@import './colors.scss';

@font-face {
  font-family: 'Roboto-Light';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto-Light'),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto-Regular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto-Regular'),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto-Medium';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto-Medium'),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fCRc4AMP6lbBP.woff2)
      format('woff2');
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto';
}

.align-self-center {
  align-self: center;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.golden {
  color: #fac628;
}

.auto-overflow {
  overflow: auto;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-label {
  background: #fff;
  padding: 0 5px;
}

.font-roboto {
  font-family: 'Roboto';
}

.font-roboto-medium {
  font-family: 'Roboto';
}

.font-roboto-light {
  font-family: 'Roboto-Light';
}

.font-roboto-regular {
  font-family: 'Roboto-Regular';
}

.pointer {
  cursor: pointer;
}

.pdf-canvas {
  canvas {
    width: 100% !important;
  }
}

.capitalize {
  text-transform: capitalize;
}

.error-message {
  display: none;
}

.error {
  .react-autosuggest__input {
    border-color: #f44336;
  }

  .error-message {
    display: block;
    color: #f44336;
    font-size: 14px;
    font-weight: 500;
  }

  .label {
    color: #f44336;
  }
}
